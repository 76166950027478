const CalendarMainRoute = '/court-calendar';
const ResourceCalendarRoute = '/resource-calendar';

export const CALENDAR_PANELS_VIEW = {
	CALENDAR: 'view-all',
	DOCKETS: 'docket-view',
	DOCKETS_LIST: 'court-dockets',
	CASE_DETAILS: 'case-details',
	COURT_DOCKET: 'court-docket',
	SETTINGS: 'court-calendar-settings',
	RESOURCE_CALENDAR: 'resource-calendar'
};

export const CALENDAR_ROUTES = {
	CALENDAR: (view, year, month, day) => {
		return [CalendarMainRoute, CALENDAR_PANELS_VIEW.CALENDAR, view, year, month, day].join('/');
	},
	DOCKETS: (docketId, selectedDate, start, end) => {
		return (
			[CalendarMainRoute, CALENDAR_PANELS_VIEW.DOCKETS, docketId].join('/') +
			`?docketDate=${selectedDate}&start=${new Date(start.toISOString()).getTime()}&end=${new Date(
				end.toISOString()
			).getTime()}`
		);
	},
	DOCKETS_LIST: (docketId) => {
		return [CalendarMainRoute, CALENDAR_PANELS_VIEW.DOCKETS_LIST, docketId].join('/');
	},
	CASE_DETAIL: (caseId) => {
		return [CalendarMainRoute, CALENDAR_PANELS_VIEW.CASE_DETAILS, caseId].join('/');
	},
	RESOURCE_CALENDAR: (view, year, month, day, type = 'default') => {
		return [
			ResourceCalendarRoute,
			CALENDAR_PANELS_VIEW.RESOURCE_CALENDAR,
			view,
			year,
			month,
			day,
			type
		].join('/');
	}
};
