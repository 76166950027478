import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: '',
	date: '',
	startTime: '',
	endTime: '',
	filters: {
		court: '',
		fromDate: '',
		toDate: ''
	},
	filteredSearch: false,
	currentSettings: [],
	refreshCourtSettings: false
};

const courtCalendarSlice = createSlice({
	initialState,
	name: 'courtCalendarSlice',
	reducers: {
		criminalDocket: (state, action) => {
			state = action.payload;
		},
		clearAll: (state) => {
			state.filters = {
				court: '',
				fromDate: '',
				toDate: ''
			};
		},
		addFilters: (state, action) => {
			state.filters[`${action.payload?.[0]}`] = action.payload?.[1];
		},
		filteredSearch: (state) => {
			state.filteredSearch = !state.filteredSearch;
		},
		currentSettings: (state, action) => {
			state.currentSettings = action.payload;
		},
		refreshCourtSettings: (state, action) => {
			state.refreshCourtSettings = action.payload;
		}
	}
});

export const {
	criminalDocket,
	clearAll,
	addFilters,
	filteredSearch,
	currentSettings,
	refreshCourtSettings
} = courtCalendarSlice.actions;

export default courtCalendarSlice.reducer;
