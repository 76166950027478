import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { LeftNavBar } from '../layout';
import { CaseService } from '../../services/CaseService';
import { useModalDialog } from '../../hooks';
import { usePagePermissions } from '../../hooks/usePageAccess';
import { HateCrimesServices } from '../../services/EventServices/HateCrimesService';
import HateCrimesModal from '../../pages/EventPage/HateCrimesModal';
import { persistHateCrimes } from '../../redux/reducers/hateCrimesData.reducer';
import { SUCCESFUL_REQUEST_CODE } from '../../utils';
import { useSelector } from 'react-redux';
import { fetchRecordsCount } from '../../redux/reducers/recordsCount.reducer';
import useUserStore from '../../redux/utils/storeUtils';

export const caseCivilFamily = ['V', 'F', 'P', 'G', 'M'];
export const caseCriminal = ['C'];

const CaseLeftSideNavigation = ({ caseId, hideNavigation = false }) => {
	const dispatch = useDispatch();
	const { permissions } = usePagePermissions([
		'casetype',
		'partyview',
		'formview',
		'case',
		'casetype',
		'caseoffense',
		'commserv',
		'bond',
		'payment',
		'installment',
		'fees',
		'courtreg',
		'financialreports',
		'event',
		'courtapptatty',
		'requestserv',
		'efile',
		'hatecrime',
		'courtsetting',
		'formview',
		'caseexhibit'
	]);

	const { getCaseData, getRecordsCountByCaseId } = CaseService();
	const { getHateCrimeAll } = HateCrimesServices();
	const { recordsCount = null } = useSelector((state) => state.recordsCount);

	const [caseData, setCaseData] = useState([]);
	const [isOpenHateCrimesModal, openHateCrimesModal, closeHateCrimesModal] = useModalDialog();
	const caseCategory = isEmpty(caseData) ? null : caseData.categoryCode;
	const [isHateCrimes, setIsHateCrimes] = useState(false);
	const { userClerks, selectedClerkId } = useUserStore();

	const fetchHateCrime = async () => {
		try {
			const hateCrimeRes = await getHateCrimeAll(caseId);
			const elements = hateCrimeRes?.data?.totalElements;
			dispatch(persistHateCrimes({ elements }));
			setIsHateCrimes(elements > 0);
		} catch (error) {
			console.error('Unable to get hate crime for case', error);
			setIsHateCrimes(false);
		}
	};

	const fetchCaseData = async () => {
		try {
			let response = await getCaseData(caseId);

			if (SUCCESFUL_REQUEST_CODE.includes(response?.status)) {
				setCaseData(response?.data);
			}
		} catch (error) {
			console.error('Unable to get case data (Case Lef Side Nav)', error);
		}
	};

	const fetchData = async () => {
		return await getRecordsCountByCaseId(caseId);
	};

	useEffect(() => {
		if (caseId) {
			fetchHateCrime();
			fetchCaseData();
			dispatch(fetchRecordsCount(fetchData));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [caseId]);

	const links = [];

	if (!hideNavigation) {
		permissions?.case.read &&
			links.push({
				text: 'Case View',
				icon: 'fas fa-landmark',
				href: `/case/${caseId}`
			});
		permissions?.partyview.read &&
			links.push({
				text: 'Parties',
				icon: 'fas fa-users',
				href: `/case/${caseId}/parties-all`
			});
		//TODO: Check for Permissions if required
		caseData.categoryCode === 'C' &&
			links.push({
				text: 'Ticket',
				icon: 'fas fa-money-check',
				href: `/case/${caseId}/ticket`
			});
		permissions?.casetype.read &&
			caseCivilFamily.includes(caseCategory) &&
			links.push({
				text: 'Case Type',
				icon: 'fas fa-balance-scale',
				href: `/case/${caseId}/case-type`
			});
		permissions?.caseoffense.read &&
			!caseCivilFamily.includes(caseCategory) &&
			links.push({
				text: 'Offenses',
				icon: 'fas fa-balance-scale',
				href: `/case/${caseId}/offense`
			});
		permissions?.commserv?.read &&
			links.push({
				text: 'Community Service',
				icon: 'fas fa-briefcase',
				href: `/case/${caseId}/community-service-all`
			});
		permissions?.bond.read &&
			links.push({
				text: 'Bonds',
				icon: 'fas fa-indent',
				href: `/case/${caseId}/bonds`
			});
		if (
			permissions?.payment.read ||
			permissions?.installment.read ||
			permissions?.fees.read ||
			permissions?.courtreg.read ||
			permissions?.financialreports.read
		) {
			links.push({
				text: 'Financials',
				icon: 'far fa-money-bill-alt',
				children: (() => {
					const children = [];
					permissions?.payment.read &&
						children.push({
							text: 'Payments',
							href: `/case/${caseId}/payment`
						});
					permissions?.installment.read &&
						children.push({
							text: 'Installments',
							href: `/case/${caseId}/installment`
						});
					permissions.fees.read &&
						children.push({
							text: 'Fees',
							href: `/case/${caseId}/fees`
						});
					permissions?.courtreg.read &&
						children.push({
							text: 'Court Registry',
							href: `/case/${caseId}/court-registry`
						});
					children.push({
						text: 'Preliminary Court Cost',
						href: `/case/${caseId}/preliminary-court-cost`
					});
					permissions?.financialreports.read &&
						children.push({
							text: 'Bill of Cost',
							href: `/case/${caseId}/bill-of-cost`
						}) &&
						children.push({
							text: 'Bill of Cost MTR',
							href: `/case/${caseId}/bill-of-cost-mtr`
						});
					return children;
				})()
			});
		}
		if (
			permissions?.event.read ||
			permissions?.courtapptatty.read ||
			permissions?.requestserv.read ||
			permissions?.efile.read ||
			permissions?.hatecrime.read
		) {
			links.push({
				text: 'Events',
				icon: 'fas fa-folder-open',
				children: (() => {
					const children = [];
					permissions?.event.read &&
						children.push({
							text: `All Events (${recordsCount?.totalEvents})`,
							href: `/case/${caseId}/events-all`
						});
					permissions?.courtapptatty.read &&
						children.push({
							text: 'Court Appointments',
							href: `/case/${caseId}/court-appointments`
						});
					permissions?.requestserv.read &&
						children.push({
							text: 'Request for Service',
							href: `/case/${caseId}/request-for-service`
						});
					permissions.efile.read &&
						children.push({
							text: 'eFiling',
							href: `/case/${caseId}/efile`
						});
					permissions?.hatecrime.read &&
						isHateCrimes &&
						children.push({
							text: 'View Hate Crime Details',
							href: `#`,
							onClick: openHateCrimesModal
						});
					return children;
				})()
			});
		}

		userClerks?.find((clerk) => clerk.clerkId === selectedClerkId)?.clerkType === 'C' &&
			caseData?.categoryCode === 'P' &&
			caseData?.subcategoryCode !== 'GUARDIANSHIP' &&
			links.push({
				text: 'Inventory',
				icon: 'fas fa-indent',
				href: `/case/${caseId}/inventory`
			});

		permissions?.courtsetting.read &&
			links.push({
				text: 'Court Settings',
				icon: 'far fa-address-book',
				href: `/case/${caseId}/court-settings/view-all`
			});
		permissions?.formview.read &&
			links.push({
				text: 'Forms',
				icon: 'fab fa-wpforms',
				href: `/case/${caseId}/form-view`
			});
		permissions?.caseexhibit.read &&
			links.push({
				text: 'Exhibits',
				icon: 'fas fa-search',
				href: `/case/${caseId}/exhibits-all`
			});
	}

	return (
		<>
			<LeftNavBar links={links} />
			{isOpenHateCrimesModal && (
				<HateCrimesModal
					show={isOpenHateCrimesModal}
					onHide={closeHateCrimesModal}
					caseId={caseId}
				/>
			)}
		</>
	);
};

export default CaseLeftSideNavigation;
