import { format, isDate, parse, parseISO } from 'date-fns';
import { timezoneFormat } from '../context/ConfigsContext/utils';
import moment from 'moment';
import 'moment-timezone';

export const DATE_FORMAT = 'MM/dd/yyyy';

export const isDateObj = (date) => {
	if (date) {
		let dateObj = new Date(date);
		return dateObj instanceof Date && !isNaN(dateObj);
	}

	return false;
};

export const dateStringToYYMMDD = (date) => {
	if (!date) {
		return '';
	}

	const d = typeof date === 'string' ? new Date(dashes2slashes(date)) : new Date(date);

	let month = (d.getMonth() + 1).toString();
	let day = d.getDate().toString();
	const year = d.getFullYear();

	if (month.length < 2) {
		month = '0' + month;
	}

	if (day.length < 2) {
		day = '0' + day;
	}

	return [year, month, day].join('-');
};

export const dateToYYMMDD = (date, outputFormat) => {
	if (!date) {
		return '';
	}
	return moment
		.utc(date)
		.local()
		.format(outputFormat ? outputFormat : 'YYYY-MM-DD');
};

export const utcToLocalInFormat = (date, dateFormat) => {
	if (!date) {
		return '';
	}
	return moment.utc(date).local().format(dateFormat);
};

export const formatDate = (date) => {
	let result = '-';
	if (!date) {
		return result;
	}
	const currentDate = new Date(date);

	if (!isDate(currentDate)) {
		return result;
	}

	const year = currentDate.getUTCFullYear();
	const paddedYear = year.toString().padStart(4, '0');
	const month = currentDate.getUTCMonth() + 1;
	const day = currentDate.getUTCDate();

	return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${paddedYear}`;
};

export const formatDateByYYYYMMDD = (date) => {
	let result = '-';

	if (!date) {
		return result;
	}

	const currentDate = new Date(date);

	if (!isDate(currentDate)) {
		return result;
	}

	const year = currentDate.getUTCFullYear();
	const paddedYear = year.toString().padStart(4, '0');
	const month = currentDate.getUTCMonth() + 1;
	const day = currentDate.getUTCDate();

	return `${paddedYear}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const getAllottedTime = (days, hs, min) => {
	let DAYS = parseInt(days * 86400000);
	let HS = parseInt(hs * 3600000);
	let MIN = parseInt(min * 60000);

	let timeInMs = DAYS + HS + MIN;
	return timeInMs;
};

export const getDaysHoursMinutes = (date) => {
	let epoch = new Date('1970-01-01');
	const date2 = new Date(date);
	let differenceInDays = date2.getTime() - epoch.getTime();
	let miliseconds = differenceInDays;

	let days, /* hours, minutes, seconds, */ total_hours, total_minutes, total_seconds;

	total_seconds = parseInt(Math.floor(miliseconds / 1000));
	total_minutes = parseInt(Math.floor(total_seconds / 60));
	total_hours = parseInt(Math.floor(total_minutes / 60));
	days = parseInt(Math.floor(total_hours / 24));

	/* 	seconds = parseInt(total_seconds % 60);
	minutes = parseInt(total_minutes % 60);
	hours = parseInt(total_hours % 24); */

	return !Number.isNaN(days) ? { days: days } : { days: 0 };
};

export const modifyDateFormat = (date, index) => {
	let day = date?.substring(8, 10);
	let month = date?.substring(5, 7);
	let year = date?.substring(0, 4);

	let time = date?.substring(11, 13) + ':' + date?.substring(14, 16);

	if (day) {
		return (date = `${month}/${day}/${year} ${time}`);
	}
};

export const MMDDYY2YYMMDD = (date) => dateStringToYYMMDD(parse(date, 'MM/dd/yyyy', new Date()));

export const isValidDate = (date) => date?.length === 10;

export const slashDateStringToDash = (dateString) =>
	dateString ? dateString.replace(/-/g, '/').replace(/T.+/, '') : '';

export const dashes2slashes = (val) => (val ? val.replace(/-/g, '/').replace(/T.+/, '') : '');

export const getFormattedDate = (date, formatIn) => {
	try {
		const parsedDate = parse(date, formatIn, new Date());
		const dateAsString = format(parsedDate, DATE_FORMAT);
		return dateAsString;
	} catch (error) {
		return '-';
	}
};

export const convertHrsToDHs = (hs) => {
	if (!hs) {
		return null;
	} else if (hs < 24) {
		return hs + ' h';
	} else if (hs >= 24) {
		let days = Math.floor(hs / 24);
		return days + ' d';
	}
};

export const dateFormatMMDDYYYY = (date) => format(parseISO(date), 'MM/dd/yyyy (hh:mm a)');

export const dateFormatYYMMDD = (date) => format(parseISO(date), 'yyyy-MM-dd');

export const dateFormatYYYYMMDDslashed = (date) => {
	if (!date) {
		return '';
	}

	const d = typeof date === 'string' ? new Date(dashes2slashes(date)) : new Date(date);

	let month = (d.getMonth() + 1).toString();
	let day = d.getDate().toString();
	const year = d.getFullYear();

	if (month.length < 2) {
		month = '0' + month;
	}

	if (day.length < 2) {
		day = '0' + day;
	}

	return [year, month, day].join('/');
};
export const dateFormatYYYYMMDDToDash = (date) => {
	if (!date) {
		return '';
	}

	const d = typeof date === 'string' ? new Date(dashes2slashes(date)) : new Date(date);

	let month = (d.getMonth() + 1).toString();
	let day = d.getDate().toString();
	const year = d.getFullYear();

	if (month.length < 2) {
		month = '0' + month;
	}

	if (day.length < 2) {
		day = '0' + day;
	}

	return [year, month, day].join('-');
};

export const parseStringDate = (date) => {
	const ISODate = new Date(date).toISOString();
	return parseISO(ISODate);
};

export const formatCorrectDate = (date) => {
	return new Date(date);
};

export const dateTOUSADate = (date) => {
	let year = date.substring(0, 4);
	let month = date.substring(5, 7);
	let day = date.substring(8, 10);
	let dateFormatUS = new Date(year, month - 1, day).toLocaleDateString();
	return dateFormatUS;
};

export const dateTimeTOUSADate = (date) => {
	if (!date) {
		return '-';
	}
	let dateGMT;
	if (date.slice(-1) !== 'Z') {
		dateGMT = date + 'Z';
	} else {
		dateGMT = date;
	}
	let dateUS = new Date(dateGMT);
	let dateFormatUS = dateUS.toLocaleString('en-US', {
		timeZone: 'America/Chicago',
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit'
	});
	return dateFormatUS;
};

export const dateToUSDate = (date) => {
	const pattern = /^\d{2}\/\d{2}\/\d{4}$/;
	return pattern.test(date) ? date : format(parseISO(date), 'MM/dd/yyyy');
};

/*** @param {date | string} date Date in ISO Format */
export const iSODateToLocal = (date, dateFormat) => {
	return format(parseISO(date), dateFormat);
};

export const addCurrentTimeToDate = (date) => {
	return dateFormatYYYYMMDDToDash(date) + new Date().toISOString().slice(10);
};

export const dateDifferenceInDays = (firstDate, secondDate) => {
	return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));
};

export function isIsoDate(inputString) {
	const pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
	return pattern.test(inputString);
}

export const isNaNDateInstance = (date) => {
	return isNaN(date);
};

export const MMDDYYYY_TO_DASHES = (inputDate, addTime) => {
	if (inputDate) {
		const dateComponents = inputDate.split('/');

		if (dateComponents.length !== 3) {
			// Invalid input format
			return inputDate;
		}

		const month = parseInt(dateComponents[0]);
		const day = parseInt(dateComponents[1]);
		const year = parseInt(dateComponents[2]);

		if (isNaN(month) || isNaN(day) || isNaN(year)) {
			// Invalid numeric values in date
			return 'Invalid date values';
		}

		// Create a Date object with the parsed values
		const parsedDate = new Date(year, month - 1, day);

		if (isNaN(parsedDate.getTime())) {
			// Invalid Date object created
			return 'Invalid date';
		}

		// Format the parsed Date object into "YYYY-MM-DD" format
		const formattedDate = `${parsedDate.getFullYear()}-${(month < 10 ? '0' : '') + month}-${
			(day < 10 ? '0' : '') + day
		}`;

		if (addTime) return `${formattedDate}T03:00:00.000Z`;

		return formattedDate;
	}

	return inputDate;
};

export const MMDDYYY_TO_ISO = (inputDate) => {
	const dateComponents = inputDate.split('/');

	if (dateComponents.length !== 3) {
		return inputDate;
	}

	const month = parseInt(dateComponents[0]);
	const day = parseInt(dateComponents[1]);
	const year = parseInt(dateComponents[2]);

	if (isNaN(month) || isNaN(day) || isNaN(year)) {
		return 'Invalid date values';
	}

	const formattedDate = new Date(year, month - 1, day);

	if (isNaN(formattedDate.getTime())) {
		return 'Invalid date';
	}

	// Create an array of day names and month names
	const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];

	// Get the day name, month name, and full year
	const dayName = dayNames[formattedDate.getDay()];
	const monthName = monthNames[formattedDate.getMonth()];
	const fullYear = formattedDate.getFullYear();

	// Format the date string
	const formattedDateString = `${dayName} ${monthName} ${day} ${fullYear} 00:00:00 GMT${
		formattedDate.getTimezoneOffset() > 0 ? '-' : '+'
	}${Math.abs(formattedDate.getTimezoneOffset() / 60)
		.toString()
		.padStart(2, '0')}${Math.abs(formattedDate.getTimezoneOffset() % 60)
		.toString()
		.padStart(2, '0')} (Argentina Standard Time)`;

	return formattedDateString;
};

export const isYYYYMMDDFormatSeparatedByDashes = (dateString) => {
	if (!dateString) return false;
	const pattern = /^\d{4}-\d{2}-\d{2}$/;
	return pattern.test(dateString);
};

export const isoDateWithZeroTime = (date) => {
	if (!isIsoDate(date)) {
		if (isYYYYMMDDFormatSeparatedByDashes(date)) {
			return date + 'T00:00:00Z';
		}
		throw new Error('Invalid input date Format!');
	}

	const localTime = format(parseISO(date), 'yyyy-MM-dd');
	return localTime + 'T00:00:00Z';
};

export const dateStringToMonthYear = (date) => {
	let dates = new Date(date.getFullYear(), date.getMonth() + 1, 1);
	dates = dates.toLocaleString('default', { month: 'long', year: 'numeric' });
	return dates;
};

export const dateToNewDate = (date) => {
	return new Date(date).toISOString().replace('Z', '');
};

export const getLocaleTimeString = (date) => {
	const timeValue = new Date(date).toLocaleTimeString('en', {
		timeStyle: 'short',
		hour12: false,
		timeZone: 'UTC'
	});

	return timeValue;
};

export function parseTimeToDate(timeString) {
	const [hours, minutes] = timeString.split(':');
	const isPM = timeString.includes('PM');
	let adjustedHours = parseInt(hours, 10);

	if (isPM && adjustedHours !== 12) {
		adjustedHours += 12;
	} else if (!isPM && adjustedHours === 12) {
		adjustedHours = 0;
	}

	const date = new Date();
	date.setHours(adjustedHours);
	date.setMinutes(parseInt(minutes, 10));
	date.setSeconds(0);
	date.setMilliseconds(0);

	return date.toISOString();
}

export const timeZoneDate = (date, timeZone) => {
	const estDateStr = moment(date).tz(timezoneFormat(timeZone));

	return estDateStr._d.toString();
};

export function convertTimeToAMPM(time) {
	// Split the time string into hours, minutes, and seconds
	const [hours, minutes, seconds] = time.split(':');

	// Convert hours to integer
	let hoursInt = parseInt(hours);

	// Determine if it's AM or PM
	let period = hoursInt >= 12 ? 'PM' : 'AM';

	// Convert hours to 12-hour format
	hoursInt = hoursInt % 12 || 12;

	// Create the formatted time string
	const formattedTime = `${hoursInt}:${minutes} ${period}`;

	return formattedTime;
}

export function isDateValid(dateString) {
	const dateObject = new Date(dateString);
	return !isNaN(dateObject) && dateObject.toString() !== 'Invalid Date';
}

export function convertISODateFormatToDateExcludingTime(isoDateString) {
	if (!isoDateString) {
		return null;
	}

	const splittedISOStringDate = isoDateString.split('T');
	const YYYYYMMDDString = splittedISOStringDate[0].split('-');
	const day = YYYYYMMDDString[2];
	const month = YYYYYMMDDString[1];
	const year = YYYYYMMDDString[0];

	let dateToReturn = new Date(year, month - 1, day);
	return dateToReturn;
}

export function createDateAsUTC(date) {
	return new Date(
		Date.UTC(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds()
		)
	);
}

export const getLocalDateTimeString = (isoString, showTime = true) => {
	const localDate = new Date(isoString);
	const date = localDate.toLocaleDateString('en-US', {
		day: 'numeric',
		year: 'numeric',
		month: 'numeric'
	});
	const time = localDate.toLocaleTimeString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true
	});
	if (showTime) return `${date} ${time}`;
	return date;
};

export const inactiveDateNextDayOfActiveDate = (date) => {
	const conDate = new Date(date);
	return conDate.setDate(conDate.getDate() + 1);
};

export const getStartDateAndEndDateOfMonthInYYYYMMDD = (date) => {
	date.setDate(1);
	const startDateYear = date.getFullYear().toString();
	const startDateMonth = (date.getMonth() + 1).toString().padStart(2, '0');
	const startDateDay = date.getDate().toString().padStart(2, '0');
	var startDate = `${startDateYear}-${startDateMonth}-${startDateDay}`;

	date.setMonth(date.getMonth() + 1, 1);
	date.setDate(date.getDate() - 1);
	const endDateYear = date.getFullYear().toString();
	const endDateMonth = (date.getMonth() + 1).toString().padStart(2, '0');
	const endDateDay = date.getDate().toString().padStart(2, '0');
	var endDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;

	return { startDate, endDate };
};

export function getYearStartAndEndDatesCourtIntepreter(date) {
	if (typeof date === 'string') {
		date = new Date(date);
	}

	if (!(date instanceof Date) || isNaN(date.getTime())) {
		throw new Error('Invalid date. Please provide a valid Date object or a string.');
	}

	const year = date.getFullYear().toString();

	if (!/^\d{4}$/.test(year)) {
		throw new Error('Invalid year format. Please provide a year in YYYY format.');
	}

	const startDate = new Date(`${year - 1}-10-01`);
	const endDate = new Date(`${year}-09-30`);

	const startDateFormatted = formatDateByYYYYMMDD(startDate);
	const endDateFormatted = formatDateByYYYYMMDD(endDate);

	return {
		startDate: startDateFormatted,
		endDate: endDateFormatted
	};
}
